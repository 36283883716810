<template>
<div class="p7 de" style="height: 100%">
  <div class="hd">
    <span @click="$router.back()">返回</span>
    <div class="action">
      <div>
<!--        <i class="iconfont icon-shoucang"></i> 收藏状态-->
      </div>
      <div style="display: none">
        <md-icon name="share" />
        分享
      </div>
    </div>

  </div>

    <div class="ibox">
        <div class="ibh">
          <div class="ibhi">
            <img :src="activity.cover" alt="" style="height: 100%;width: 100%;object-fit: contain">
<!--            <i class="iconfont icon-bofang dm" id="bofang"></i>-->
<!--            <i class="iconfont icon-fangda "></i>-->
          </div>

          <div class="ibhf">
            <div class="ibhft ft3">{{ activity.title }}</div>
            <div class="ibhfb">
              <div class="ft5 ">{{activity.addTime|format('YYYY-MM-DD HH:mm')}} 发布</div>
            </div>
          </div>
          <md-field>
            <md-detail-item title="类别" :content="activity.type|actypeStr"  />
            <md-detail-item title="地址" :content="activity.address" />
            <md-detail-item title="活动时间" >
              {{activity.sTime|format('YYYY-MM-DD HH:mm')}} -  {{activity.eTime|format('YYYY-MM-DD HH:mm')}}
            </md-detail-item>
            <md-detail-item title="开放预约" >
              {{activity.bookStime|format('YYYY-MM-DD HH:mm')}} - {{activity.bookEtime|format('YYYY-MM-DD HH:mm')}}
            </md-detail-item>
            <md-detail-item title="活动人数" :content="activity.peopleLimit" />

          </md-field>
        </div>
<!-- 有投票活动 -->
      <template v-if="activity.isvote">
        <md-tabs>
          <md-tab-pane class="content" name="p1" label="活动描述">
            <div class="ibi">
              <div class="ibih">
                <div class="ft2 lt" >活动描述</div>
              </div>
              <div class="ibii mct" v-html="activity.desc" style="padding:15px">

              </div>

            </div>
            <div v-if="options.length && activity.state == 1 " >

              <md-field>
                <div style="display: flex;line-height: 2;">请填写以下内容 </div>
                <template   v-for="item of options">
                  <md-field-item :title="item.keyname" v-if="item.type.includes('选')">
                    <template v-if="item.type==='单选'" slot="right">
                      <div style="display: flex;flex-wrap: wrap;" class="int">
                        <md-radio :name="op.name" v-model="item.value" :label="op.name" inline v-for="op of item.options" />
                      </div>
                    </template>
                    <template v-else>
                      <md-check-group v-model="item.value" slot="right">
                        <md-check-box :name="op.name" v-for="op of item.options" >{{ op.name }}</md-check-box>
                      </md-check-group>
                    </template>
                    <span style="color:red" slot="left" v-if="item.required">*</span>
                  </md-field-item>
                  <template  v-else-if="item.type  ==='地址'">
                    <md-field-item
                        title="选择所在地"
                        arrow="arrow-right"
                        :addon="areaStr"
                        @click="showArea = true">
                    </md-field-item>
                    <md-input-item
                        ref="input0"
                        title="具体地址"
                        placeholder="具体到门牌号地址"
                        v-model="detailAdd"
                        align="right"
                    >
                      <span style="color:red" slot="left" >*</span>
                    </md-input-item>
                  </template>
                  <template  v-else-if="item.type  ==='下拉'">
                    <md-field-item
                        :title="item.keyname"
                        :addon="selectorValue"
                        @click="showSelector=true"
                        align="right"
                        arrow
                    >
                    </md-field-item>
                    <md-selector
                        v-model="item.value"
                        :data="item.options.map(r=>({value:r.name,text:r.name}))"
                        default-value="1"
                        max-height="320px"
                        title="请选择"
                        large-radius
                        @choose="(({value})=> onSelectorChoose(item,value) )"
                    ></md-selector>
                  </template>

                  <template  v-else-if="item.type ==='年龄组'">
                    <md-field-item
                        :title="item.keyname"
                        :addon="AgeSTR"
                        @click="showAgeArr=true"
                        align="right"
                        arrow
                    >
                    </md-field-item>
                    <md-selector
                        v-model="showAgeArr"
                        :data="ageSelect.map(r=>({value:r.name,text:r.name +' ' + r.agerange.split(',').join('至')   }))"
                        default-value="1"
                        max-height="320px"
                        title="请选择"

                        large-radius
                        @choose="(({value})=> onAgeChoose(item,value) )"
                    ></md-selector>
                  </template>


                  <md-input-item
                      ref="input0"
                      :title="item.keyname"
                      :placeholder="`请填写${item.keyname}`"
                      v-else
                      v-model="item.value"
                      align="right"
                  >
                    <span style="color:red" slot="left" v-if="item.required">*</span>
                  </md-input-item>

                </template>
              </md-field>
            </div>
          </md-tab-pane>
          <md-tab-pane class="content" name="p2" label="投票">
            <div class="tgct">
              <div class="tgitem" v-for="item of activity.tglist || []">
                <div class="ticover">
                  <img :src="item.cover" alt="">
                </div>
                <div class="tgtitle">{{item.actitle}} </div>
                <div class="tginfo"> <span>编号:{{item.id}}</span> <span>票数:{{item.vote}}</span> </div>
                <div class="tgbt" @click="dovote(item.from,item.id)">立即投票</div>
              </div>
            </div>
          </md-tab-pane>

        </md-tabs>
      </template>
      <template v-else>
        <div class="ibi">
          <div class="ibih">
            <div class="ft2 lt" >活动描述</div>
          </div>
          <div class="ibii mct" v-html="activity.desc" style="padding:15px">

          </div>

        </div>
        <div v-if="options.length && activity.state == 1 " >

          <md-field>
            <div style="display: flex;line-height: 2;">请填写以下内容  </div>
            <template   v-for="item of options">
              <md-field-item :title="item.keyname" v-if="item.type.includes('选')">
                <template v-if="item.type==='单选'" slot="right">
                  <div style="display: flex;flex-wrap: wrap;" class="int">
                    <md-radio :name="op.name" v-model="item.value" :label="op.name" inline v-for="op of item.options" />
                  </div>
                </template>
                <template v-else>
                  <div style="width: 100%;flex-grow: 1;flex-wrap: wrap;">
                    <md-check-group v-model="item.value" slot="right" >
                      <md-check-box :name="op.name" v-for="op of item.options" style="margin-right:10px">{{ op.name }}</md-check-box>
                    </md-check-group>
                  </div>
              
                </template>
                <span style="color:red" slot="left" v-if="item.required">*</span>
              </md-field-item>
              <template  v-else-if="item.type  ==='地址'">
                <md-field-item
                    title="选择所在地"
                    arrow="arrow-right"
                    :addon="areaStr"
                    @click="showArea = true">
                </md-field-item>
                <md-input-item
                    ref="input0"
                    title="具体地址"
                    placeholder="具体到门牌号地址"
                    v-model="detailAdd"
                    align="right"
                >
                  <span style="color:red" slot="left" >*</span>
                </md-input-item>
              </template>
              <template  v-else-if="item.type  ==='下拉'">
                <md-field-item
                    :title="item.keyname"
                    :addon="selectorValue"
                    @click="showSelector=true"
                    align="right"
                    arrow
                >
                </md-field-item>
                <md-selector
                    v-model="item.value"
                    :data="item.options.map(r=>({value:r.name,text:r.name}))"
                    default-value="1"
                    max-height="320px"
                    title="请选择"
                    large-radius
                    @choose="(({value})=> onSelectorChoose(item,value) )"
                ></md-selector>
              </template>

              <template  v-else-if="item.type ==='年龄组'">
                <md-field-item
                    :title="item.keyname"
                    :addon="AgeSTR"
                    @click="showAgeArr=true"
                    align="right"
                    arrow
                >
                  <span style="color:red" slot="left" >*</span>
                </md-field-item>
                <md-selector
                    v-model="showAgeArr"
                    :data="ageSelect.map(r=>({value:r.name,text:r.name +' ' + r.agerange.split(',').join('至')+'岁' + `${r.left?' 剩余'+r.left+'位':'' }`  }))"
                    default-value="1"
                    max-height="320px"
                    title="请选择"
                    large-radius
                    @choose="(({value})=> onAgeChoose(item,value) )"
                ></md-selector>
              </template>


              <md-input-item
                  ref="input0"
                  :title="item.keyname"
                  :placeholder="`请填写${item.keyname}`"
                  v-else
                  v-model="item.value"
                  align="right"
              >
                <span style="color:red" slot="left" v-if="item.required">*</span>
              </md-input-item>

            </template>
          </md-field>
        </div>
      </template>


    </div>
    <div class="bbox" v-if="activity.isbook">

          <div v-if="activity.state == 1  && activity.isvote == 1 && activity.ispublish ==1" @click="joinac"> 立即预约</div>
          <div v-else-if="activity.state == 1  && activity.isvote !=1 " @click="joinac"> 立即预约</div>
          <div v-else-if="activity.state == 0"> 即将开放预约</div>
          <div v-else-if="activity.state == 2"> 进行中</div>
          <div v-else-if="activity.state == 3" > 已结束</div>
          <div v-else-if="activity.state == 5"> 即将开始</div>
          <div v-else>等待中</div>
    </div>


  <md-picker

      ref="picker1"
      v-model="showArea"
      :data="areaData"
      :cols="3"
      is-cascade
      large-radius
      title="选择省市区/县"
      @confirm="ondAreaConfirm"
  ></md-picker>
</div>
</template>
<script>
const  data  = require('province-city-china/dist/level.json')
import district from 'mand-mobile/components/picker/demo/data/district'
console.log(district)
export default {
    name: "acDetail",
    data(){
      return {

        showSelector:false,
        showAgeArr :false,
        AgeSTR:'',
        selectorValue:'',
        selectorArr:[
          [

          ],
        ],
        showArea:false,
        areaStr:'',
        activity:{},
        options:[],
        optarr:[],
        areaData:[],
        detailAdd:''
      }
    },
  created() {
     this.getData()
     data.forEach(item=>{

              item.labeil = item.name;
              item.value = item.name;
              item.text = item.name;

              item.children.forEach(ite1 => {
                ite1.labeil = ite1.name;
                ite1.value = ite1.name;
                ite1.text = ite1.name;
                if(ite1.children) {
                    ite1.children.forEach( ite2=>{
                      ite2.labeil = ite1.name;
                      ite2.value = ite2.name;
                      ite2.text = ite2.name;
                    })
                }
              })


    })
     this.areaData =[ data ];
     console.log('kkkk',this.areaData)
  },
  watch:{
    ageSelect(nv){
      this.options.find(r=>r.type==='年龄组').value=""
    }
  },
  computed:{
    ageSelect(){
      // 查看选项中是否有年龄组

      let agarr = this.options.find(r=>r.type==='年龄组')
      if(!agarr || !agarr.linkIdcard )   return []

       const { groupInfo } = this.activity;
      if(groupInfo){
         agarr.options = agarr.options.map( r=> {
            const _inone = groupInfo.find(it=>it.name === r.name);
            if(r.peoplelimit){
              r.left =  r.peoplelimit - _inone.count
              r.left =  r.left > 0 ? r.left:0;
            }
            return r;
         })
      }


      if(this.options.find(r=>r.type==='身份证') && this.options.find(r=>r.type==='身份证').value) {
        const idnum = this.options.find(r=>r.type==='身份证').value;
        if (idnum.length!==18) return agarr.options

        // 先得到 身份证年份
        const year  = parseInt(idnum.slice(6,10));
        const mon =  parseInt(idnum.slice(10,12));
        const day = parseInt(idnum.slice(12,14));
        const _d = new Date();
        _d.setFullYear(year);
        _d.setMonth(mon-1);
        _d.setDate(day);

        let sc = new Date() - _d;

        let yt = 365*24*60*60*1000;
        const year2now = Math.floor(sc/yt)
        console.log('year2now',mon)
        // 过滤出可以选择的年龄组
        const _output = agarr.options.filter(r=>{
          const [min,max] = r.agerange.split(",");
          if(min&&max) return year2now>= parseInt(min) && year2now <= parseInt(max);
          if(min) return year2now >= parseInt(min)

        })
        return _output

      }

      return agarr.options

    }
  },
  methods:{
    async dovote(from,id){

      const acid =  this.$route.query.id;

      const { uid } = this.$store.state.userInfo;
      if(!uid) return  this.$toast.info('请先登录')

      const res = await this.$api.post('/dovote',{
        uid,
        acid,
        from:from,
        actionid: id
      })

      const { success ,data,info}   =res.data;
      if(success){
        this.$toast.succeed('投票成功')
        this.getData();
      }else{
        this.$toast.failed(info||'投票有误')
      }

    },
    onAgeChoose(item,value){

      item.value = value
      this.AgeSTR = value
    },
    onSelectorChoose(item,value){
      item.value = value
      this.selectorValue = value
    },
    ondAreaConfirm(columnsValue){
        let resStr = ''
         for(const item of columnsValue){
           if(!item) continue;
           resStr = resStr +item.text+'/'
         }
         this.areaStr = resStr.slice(0,resStr.length-1)
    },
        async getData(){
          const id = this.$route.query.id
          let res = ''
          try{
             res = await this.$api.post('/mapi/acdetail',{id})
          }catch (e) {

          }

          if(!res) return this.$toast.info('当前活动不存在')

          this.activity = res.data
          // 处理图片压缩

          this.$nextTick(()=>{
            let doms =  document.querySelectorAll('.mct img')
            for(const dom of doms){
              console.log(dom)
              dom.src = dom.src+'-wzmobile'
            }
          })



          this.options = this.activity.options? JSON.parse(this.activity.options):[]

          // 如果有下拉内容

          const sele =this.options instanceof Array ?this.options.find(r=>r.type==='下拉'):''
          if(sele) this.selectorArr = sele.options.map(r=>({value:r.name,text:r.name}))
          // console.log('12312312321',this.selectorArr)


        },
        async joinac(){
          const id = this.$route.query.id;
          const uid = this.$store.state.userInfo.uid;


          // 如果包含有地址信息的话
          if(this.options.find(r=>r.type=='地址')){
            this.options.find(r=>r.type=='地址').value = this.areaStr + "," +this.detailAdd
          }

          const _acinfo =  this.options.map(r=>{
            const { keyname,value } = r;
            return {keyname,value:value?value:''}
          })


          console.log('the post data', this.options)
          // 做一下检测
          let _one = this.ageSelect.find(r=>r.name === this.AgeSTR )
          if(_one){
            const { left } = _one
            if(left === 0) return this.$toast.failed('当前组别已满')
          }


          let checlist =   this.options.map(r=>{

            let isOk =  true
            let kname = ''
            let errmsg =''



            const { type ,value,keyname } = r
            console.log(type,value)

            // 数据过滤
            if(type=='身份证' && value.length!=18 ){
              isOk = false;
              errmsg = '请填写正确的身份证'
            }
            if(type=='电话号码' && !/1[3456789]\d{9}/.test(value)) {
              isOk = false;
              errmsg = '请填写正确的电话号码'
            }


            if(r.required&&!r.value){
              isOk = false
              errmsg = "请填写"+ keyname
            }
            return {
              isOk,
              errmsg
            }
          })



          let err = checlist.find(r=>r.isOk === false);
          if(err) return this.$toast.info(err.errmsg)


          const activityInfo = JSON.stringify(_acinfo)
          let res = await  this.$api.post('/mapi/joinAc',{uid,id,activityInfo,from:'wx'})
          const { success,data,info } = res.data
          if(!success) return this.$toast.failed(info)
          setTimeout(()=>window.location.reload(),1500);
          this.$toast.succeed('预约成功')
        }
    }
};
</script>

<style lang="scss" scoped>

@function vw($px) {
  @return ($px / 750) * 100vw;
}
html,body{
    margin: 0;
    padding: 0;
    height: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    .lt{
        float:left;
    }
    .rt{
        float:right;
    }
    .at{
        margin: 0 auto;
    }
    .hc{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .cr{
        position: absolute;
        top: 50%;
        transform: translatey(-50%);
    }
    .dm{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translatey(-50%);
    }
    .ft1{
        font-size: vw(32);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3C3C3C;

    }
    .ft2{
        font-size: vw(32);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3C3C3C;

    }
    .ft3{
        font-size: vw(26);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3C3C3C;
    }
    .ft4{
        font-size: vw(24);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3C3C3C;
    }
    .ft5{
        font-size: vw(24);
        transform: scale(.83);
        transform-origin: 0;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3c3c3c;
    }
    .iconfont{
        text-align:center;
        color: #3c3c3c;
    }

    .p7{

        width: vw(750);
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        flex-direction:column;

        .hd{
            width:100%;
            height: 100px;
            background: #e5e5e5;

            .hdfb{
               height: vw(48);
               line-height: vw(48);
               padding:  vw(66) vw(30) vw(10);
               display: flex;
               justify-content:space-between;
               position: relative;
            }

        }
        .ibox{
            height: 0;
            flex-grow:1;
            overflow-y: auto;
            .ibh{

                width: 100%;
                border-bottom: vw(10) solid #f4f4f4;
                .ibhi{
                    height: vw(442);
                    background-size:contain;
                    position: relative;
                    .ibhi>i{
                        position: absolute;
                    }
                    i:first-child {
                        font-size: vw(120);
                        opacity: 0.5;
                    }
                    i:last-child{
                        font-size: vw(40);
                        position: absolute;
                        bottom: vw(28);
                        right: vw(34);
                        color: #fff;
                    }
                }
                .ibhf{
                    height: vw(144);
                    padding: vw(20) vw(58);
                    .ibhft{
                        height: vw(60);
                        line-height: vw(30);
                        width: vw(520);
                    }
                    .ibhfb{
                        height: vw(28);
                        line-height: vw(28);
                        margin-top: vw(10) ;

                    }
                }


            }
            .ibi{
                img{
                  width: 100%;
                }

                .ibih{
                    height: vw(124);
                    line-height: vw(124);
                    padding-left: vw(24) ;
                }
                .ibii{
                    min-height: vw(636);

                    .img {
                        height: vw(450);
                        width: vw(686);
                        background-image:url(https://fakeimg.pl/686x450);
                        background-size:contain;
                    }
                    .txt{
                        height: vw(102);
                        line-height: vw(34);
                        margin-top: vw(34);
                        margin-right: vw(40) ;

                    }

                }


            }





        }
        .bbox{
            height: vw(75);
            line-height: vw(75);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content:space-around;
            padding-bottom: 10px;

          &>div{
            width: 90%;
            margin:0 auto;
            text-align: center;
            background: red;
            border-radius: 10px;
            color:#fff
          }


        }




    }

}
.int{
  width: 400px;
  overflow: auto;
}
.ibii img{
  width: 100%!important;
}
::v-deep .md-field-item-title{
  width: 140px!important;
}

.tgct{
  padding:10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

}
.tgitem{
  width: 46%;
  margin-bottom: 25px;
  background: #f1f1f1;
  .ticover{
    height: 260px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .tgbt{
    line-height: 65px;
    text-align: center;
    background: #ed6262;
    color:#fff
  }

  .tgtitle{
    padding:0 8px;
    box-sizing: content-box;
    font-size: 14px;
    color:#444;
    font-weight: 500;
    line-height: 2;
  }
  .tginfo{
    box-sizing: content-box;
    padding:0 8px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    line-height: 1.5;
  }
}
</style>
