import { render, staticRenderFns } from "./acDetail.vue?vue&type=template&id=1b53b8c9&scoped=true"
import script from "./acDetail.vue?vue&type=script&lang=js"
export * from "./acDetail.vue?vue&type=script&lang=js"
import style0 from "./acDetail.vue?vue&type=style&index=0&id=1b53b8c9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.31_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_xh5j7fgtzlmas7btcgxh6ot4kq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b53b8c9",
  null
  
)

export default component.exports